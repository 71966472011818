import React, { useState, useEffect } from "react"
import Header from "./Header"
import MobileMenu from "./MobileMenu"
import Footer from "./Footer"
import { Global, css } from "@emotion/core"
import { Box } from "theme-ui"

// css for third party libraries
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface LayoutProps {
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const [shrinkHeight, setShrinkHeight] = useState(false)

  useEffect(() => {
    // Todo: test performance and throttle
    const onScroll = e => {
      if (
        document.body.scrollTop > 90 ||
        document.documentElement.scrollTop > 90
      ) {
        setShrinkHeight(true)
      } else {
        setShrinkHeight(false)
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  })

  return (
    <div>
      <Header shrinkHeight={shrinkHeight} />
      <MobileMenu shrinkHeight={shrinkHeight} />
      <Box pt={shrinkHeight ? [5, 6] : 0}>
        {props.children}
        <Footer />
      </Box>
      <Global
        styles={css`
          html,
          body {
            -webkit-tap-highlight-color: rgba(
              255,
              255,
              255,
              0
            ); /* Disable blue highlight on touch on touch devices */
          }
          * {
            outline-color: #068ff4;
          }
          @media (max-width: 800px) {
            html,
            body {
              max-width: 100%;
              overflow-x: hidden;
            }
          }
          .slick-dots {
            margin-bottom: 0px !important;
          }
          .slick-dots li {
            width: auto;
            height: auto;
            margin: 0 5px;
          }
          .slick-dots li button {
            width: 25px;
            height: 4px;
            padding: 0;
            background-color: #ffffff89;
            transition: all 0.3s;
            transform-origin: 0 100%;
          }
          .slick-dots li button::before {
            content: none;
          }
          .slick-dots .slick-active button {
            background-color: #ffffff;
          }
          @media (max-width: 480px) {
            .slick-dots {
              display: none;
            }
          }
          html {
            font-size: 16px;
          }
          @media (max-width: 768px) {
            html {
              font-size: 15px;
            }
          }
        `}
      />
    </div>
  )
}

export default Layout
