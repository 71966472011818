/** @jsx jsx */
import React from "react"
import Link from "gatsby-link"
import { Box, jsx } from "theme-ui"

interface StyledNavLinkProps {
  children: React.ReactNode
  to: string
  partiallyActive?: boolean
}

const StyledNavLink = (props: StyledNavLinkProps) => {
  const { children, to } = props
  return (
    <Box px={4} py={2}>
      <Link
        to={to}
        activeClassName="active"
        sx={{
          py: 1,
          color: "text",
          textDecoration: "none",
          letterSpacing: "2px",
          textTransform: "uppercase",
          position: "relative",
          transition: "all 0.3s",
          "&:hover": {
            color: "primary",
          },
          "&.active": {
            color: "primary",
          },
          "&::after": {
            position: "absolute",
            content: '""',
            height: "3px",
            left: "0",
            bottom: "0",
            width: "100%",
            bg: "primary",
            transition: "transform 250ms ease-in",
            transformOrigin: "right",
            transform: "scaleX(0)",
          },
          "&:hover::after": {
            transformOrigin: "left",
            transform: "scaleX(1)",
          },
        }}
        partiallyActive={props.partiallyActive}
      >
        {children}
      </Link>
    </Box>
  )
}

export default StyledNavLink
