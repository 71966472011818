/** @jsx jsx */
import React from "react"
import {
  Box,
  Flex,
  Grid,
  Text,
  Heading,
  Container,
  jsx,
  Divider,
} from "theme-ui"
import FacebookIcon from "../../images/facebook.svg"
import WhatsappIcon from "../../images/whatsapp.svg"
import InstagramIcon from "../../images/instagram.svg"
import Location from "../../images/location.png"
import Logo from "../../images/logo2.svg"
import protectedData from "../../images/protectedData.png"
import Mail from "../../images/mail.png"
import School from "../../images/school.png"
import TamilNadu from "../../images/tamilNadu.png"
import IAS from "../../images/Ias.png"

const Footer = () => {
  return (
    <Box py={40} px={50} mt={[5, null, null, 7]} bg="#f5f5f5">
      <Flex
        sx={{
          justifyContent: [
            "center",
            "center",
            "space-between",
            "space-between",
            "space-between",
          ],
          flexDirection: ["column", "column", "column", "row", "row"],
          width: ["100%", "55%", "55%", "55%", "55%"],
          gap: [30, 0, 0, 0, 0],
        }}
      >
        <Flex sx={{ gap: "10px" }}>
          <a target="_blank" rel="noopener noreferrer">
            <img sx={{ width: "30px" }} src={WhatsappIcon} alt="whatsapp" />{" "}
          </a>
          <a target="_blank" rel="noopener noreferrer">
            {" "}
            <img
              sx={{ width: "30px" }}
              src={InstagramIcon}
              alt="instagram"
            />{" "}
          </a>
          <a target="_blank" rel="noopener noreferrer">
            <img sx={{ width: "30px" }} src={FacebookIcon} alt="facebook" />
          </a>
        </Flex>
        <Flex sx={{ flexDirection: "column", gap: "20px" }}>
          <img height="70" src={Logo} alt="logo" />
        </Flex>
      </Flex>

      <Box mt={50}>
        <Divider color="black" />
        <Flex
          sx={{
            justifyContent: [
              "center",
              "center",
              "space-between",
              "space-between",
              "space-between",
            ],
            flexDirection: ["column", "column", "column", "row", "row"],
            width: "100%",
            gap: [20, 20, 20, 0, 0],
          }}
        >
          <Flex sx={{ gap: 10 }}>
            <img height="30" width="30" src={Mail} alt="logo" />
            <Text style={{ fontWeight: "700" }} color="#313131">
              sarkariasacademy2023@gmail.com
            </Text>
          </Flex>

          <Flex sx={{ gap: 10 }}>
            <img height="30" width="30" src={Location} alt="logo" />
            <Text style={{ fontWeight: "700" }} color="#313131">
              Locate Us
            </Text>
          </Flex>
        </Flex>
        <Divider color="black" />
      </Box>

      <Flex
        mt={40}
        sx={{
          justifyContent: [
            "center",
            "center",
            "space-between",
            "space-between",
            "space-between",
          ],
          width: "100%",
          flexDirection: ["column", "column", "column", "row", "row"],
          gap: [20, 0, 0, 0],
        }}
      >
        <Flex ml={["-10px", "-10px", "-10px", "0px"]} sx={{ gap: 10 }}>
          <img width="50" src={IAS} alt="logo" />
          <Flex sx={{ flexDirection: "column" }}>
            <Text style={{ fontWeight: "700" }} color="#313131">
              IAS
            </Text>
            <Text style={{ fontSize: "10px" }}>PARLIAMENT</Text>
          </Flex>
        </Flex>

        <Flex ml={["-5px", "-5px", "-5px", 90, 90]} sx={{ gap: 10 }}>
          <img height="40" width="40" src={TamilNadu} alt="logo" />
          <Flex sx={{ flexDirection: "column" }}>
            <Text
              ml={["5px", "0"]}
              style={{ fontWeight: "700" }}
              color="#313131"
            >
              TNPSC
            </Text>
            <Text ml={["5px", "0"]} style={{ fontSize: "10px" }}>
              THERVU PETTAGAM
            </Text>
          </Flex>
        </Flex>
        <Flex ml={["-10px", "-10px", "-10px", 0, 0]} sx={{ gap: 10 }}>
          <img width="50" src={School} alt="logo" />
          <Flex sx={{ flexDirection: "column" }}>
            <Text style={{ fontSize: "10px" }}>SARKAR SCHOOL OF</Text>
            <Text style={{ fontWeight: "700" }} color="#313131">
              BANKING
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        mt={40}
        sx={{
          justifyContent: [
            "center",
            "center",
            null,
            "space-between",
            "space-between",
          ],
          flexDirection: ["column", "column", "column", "row", "row"],
          width: "100%",
          gap: [20, 20, 20, 10, 10],
        }}
      >
        <Flex sx={{ flexDirection: "column", gap: "10" }}>
          <Text style={{ fontWeight: "700" }} color="#313131">
            New No.15,Old No.93 W-Block,
          </Text>
          <Text style={{ fontWeight: "700" }} color="#313131">
            Plot No.4663,2nd Street,Anna Nagar
          </Text>
          <Text style={{ fontWeight: "700" }} color="#313131">
            Chennai, 600040
          </Text>
        </Flex>

        <Flex
          ml={[0, 0, 0, 0, -80]}
          sx={{ flexDirection: "column", gap: "10px" }}
        >
          <Text color="#313131">
            <Flex ml={[0, 0, 0, 0, "25px"]} sx={{ gap: "5px" }}>
              <img width={25} src={protectedData} alt="logo" /> Privacy Policy
            </Flex>
          </Text>
          <Box ml={[0, "10px"]}>
            <Text color="#313131">2019 Sarkar IAS Academy</Text>
          </Box>
        </Flex>

        <Flex
          sx={{ display: ["none", "none", "block"], flexDirection: "column" }}
        >
          <Text style={{ fontWeight: "700", gap: 10 }}>
            Contact Us :9962600037
          </Text>
          <Text
            ml={[0, 0, 0, 90]}
            style={{ fontWeight: "700" }}
           
          >
            9962600038
          </Text>
          <Text
            ml={[0, 0, 0, 90]}
            style={{ fontWeight: "700" }}
           
          >
            9962600039
          </Text>
        </Flex>
        <Flex sx={{ display: ["block", "block", "none"], fontSize: "14px" }}>
          <Text style={{ fontWeight: "700" }}>
            Contact Us:9962600037, 9962600038,9962600039
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Footer
