/** @jsx jsx */
import React, { useState } from "react"
import StyledNavLink from "./StyledNavLink"
import StyledLinkDecoration from "./StyledLinkDecoration"
import { Flex, Container, Box, Divider, jsx } from "theme-ui"
import Menu from "../components/Menu"
import SocialIcons from "../components/SocialIcons"
import { Link } from "gatsby"
import MenuData, { MenuItem } from "../data/menu"
import EnrollButton from "./EnrollButton"

// images and icons
import LogoImage from "../../images/logo.svg"
import { OldPhone } from "@emotion-icons/entypo/OldPhone"
import { ChevronDown } from "@emotion-icons/boxicons-regular/ChevronDown"

const SocialSection = (): JSX.Element => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        position: "absolute",
        top: "0.5em",
        right: "1.7em",
      }}
    >
      <SocialIcons />
      <Divider
        ml={-2}
        sx={{
          transformOrigin: "center center",
          transform: "rotate(90deg)",
          width: "30px",
        }}
      />
      <Box
        ml={2}
        mr={3}
        sx={{
          display: "inline-flex",
          fontWeight: "bold",
          alignItems: "center",
        }}
      >
        <span sx={{ mr: 2 }}>
          <OldPhone size="1.6rem" />
        </span>
        <span>+91-9962600038</span>
      </Box>
      <EnrollButton />
    </Flex>
  )
}

interface LogoProps {
  shrinkHeight: boolean
}

const Logo = (props: LogoProps): JSX.Element => {
  const { shrinkHeight } = props

  return (
    <Link to="/">
      <img
        sx={{
          height: shrinkHeight ? "35px" : "50px",
          transition: "all 0.3s",
        }}
        src={LogoImage}
        alt="logo"
      />
    </Link>
  )
}

interface HeaderProps {
  shrinkHeight: boolean
}

const Header = (props: HeaderProps): JSX.Element => {
  const [openSubMenuItems, setOpenSubMenuItems] = useState<number[]>([])
  const { shrinkHeight } = props

  const closeSubMenu = (index: number) => {
    if (!openSubMenuItems.includes(index)) {
      return
    }
    setOpenSubMenuItems(
      openSubMenuItems.filter(itemIndex => itemIndex !== index)
    )
  }

  const openSubMenu = (index: number) => {
    if (openSubMenuItems.includes(index)) {
      return
    }
    setOpenSubMenuItems([...openSubMenuItems, index])
  }

  const renderMenuItem = (item: MenuItem, index: number) => {
    if (!item.hasSubMenu) {
      return (
        <StyledNavLink
          key={index}
          to={item.href}
          partiallyActive={item.partiallyActive === true}
        >
          {item.title}
        </StyledNavLink>
      )
    }

    const isSubMenuOpen = openSubMenuItems.includes(index)

    return (
      <Flex
        key={index}
        onMouseEnter={() => openSubMenu(index)}
        onMouseLeave={() => closeSubMenu(index)}
        sx={{ position: "relative" }}
      >
        <StyledLinkDecoration>
          <Flex>
            {item.title}
            <ChevronDown size="1.5rem" />
          </Flex>
        </StyledLinkDecoration>
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            minWidth: "250px",
            left: "0%",
            zIndex: 1,
          }}
        >
          <Menu
            open={isSubMenuOpen}
            links={item.subMenuItems.map(item => ({
              to: item.href,
              text: item.title,
            }))}
          />
        </Box>
      </Flex>
    )
  }

  return (
    <Box
      sx={{
        ...(shrinkHeight
          ? {
              position: "fixed",
              top: "0",
              boxShadow: 1,
            }
          : {}),
        zIndex: 3,
        width: "100%",
        bg: "rgba(255, 255, 255, 0.95)",
        transition: "all 0.3s",
        "@media (max-width: 800px)": {
          "&": {
            display: "none",
          },
        },
      }}
    >
      <Container px={4} sx={{ position: "relative" }}>
        <Flex
          px={2}
          pt={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Logo shrinkHeight={false} />
          <Box pt={4}>
            <Box pt={1}>
              <Flex sx={{ transition: "all 0.3s" }} pt={2}>
                {MenuData.map(renderMenuItem)}
                <SocialSection />
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
