import React from "react"
import { Button, Text } from "theme-ui"
import { Link } from "gatsby"

const EnrollButton = (): JSX.Element => {
  return (
    <Link to="/enroll">
      <Button
        sx={{
          backgroundImage: "linear-gradient(#77c6a1 , #04d072)",
          borderRadius: "0.5em",
          fontWeight: "bold",
          px: [3, 4],
          fontSize: [1, 2],
        }}
      >
        <Text variant="caps">Admission</Text>
      </Button>
    </Link>
  )
}

export default EnrollButton
