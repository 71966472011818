/** @jsx jsx */
import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import MenuData, { MenuItem, SubMenuItem } from "../data/menu"
import { Box, Flex, jsx } from "theme-ui"
import AnimateHeight from "react-animate-height"

// icons
import { Close } from "@emotion-icons/evaicons-solid/Close"
import { Link } from "gatsby"
import { ChevronDown } from "@emotion-icons/boxicons-regular/ChevronDown"

interface DrawerProps {
  open: boolean
  onClose: () => void
}

const Drawer = (props: DrawerProps): JSX.Element => {
  const { open, onClose } = props
  const [translateX, setTranslateX] = useState<number | string>("-102%")
  const [animatable, setAnimatable] = useState(false)
  const sideBarRef = useRef(null)
  const [startX, setStartX] = useState(0)
  const [currentX, setCurrentX] = useState(0)

  const [openSubMenuItems, setOpenSubMenuItems] = useState<number[]>([])

  useEffect(() => {
    if (sideBarRef.current) sideBarRef.current.inert = true
    return () => {
      if (sideBarRef.current) sideBarRef.current.inert = false
    }
  }, [])

  const toggleSubMenu = (index: number) => {
    if (openSubMenuItems.includes(index)) {
      setOpenSubMenuItems(
        openSubMenuItems.filter(itemIndex => itemIndex !== index)
      )
      return
    }

    setOpenSubMenuItems([...openSubMenuItems, index])
  }

  useEffect(() => {
    if (open) {
      openMenu()
    } else {
      closeMenu()
    }
  }, [open])

  const openMenu = (e?) => {
    if (sideBarRef.current) sideBarRef.current.inert = false
    if (e) e.preventDefault()
    setTranslateX(0)
    setAnimatable(true)
  }

  const closeMenu = (e?) => {
    if (sideBarRef.current) sideBarRef.current.inert = true
    onClose()
    setTranslateX("-102%")
    setAnimatable(true)
  }

  const handleTouchStart = e => {
    setStartX(e.touches[0].pageX)
    setCurrentX(e.touches[0].pageX)
  }

  const handleTouchMove = e => {
    setCurrentX(e.touches[0].pageX)
    const translateX = Math.min(0, currentX - startX)
    setTranslateX(translateX)
  }

  const handleTouchEnd = e => {
    const translateX = Math.min(0, currentX - startX)
    if (translateX < -50) {
      closeMenu()
    } else {
      openMenu()
    }
  }

  const blockClicks = e => {
    e.stopPropagation()
  }

  const handleTransitionEnd = e => {
    setAnimatable(false)
  }

  const renderMenuItem = (item: MenuItem, index: number) => {
    if (!item.hasSubMenu) {
      return (
        <Box key={index}>
          <Link
            sx={{
              px: 4,
              py: 3,
              display: "inline-block",
              width: "100%",
              textDecoration: "none",
              color: "accent",
              borderBottom: "1px solid #f0f0f0",
              "&:active": {
                color: "primary",
              },
            }}
            to={item.href}
            onClick={closeMenu}
          >
            {item.title}
          </Link>
        </Box>
      )
    }

    const isSubMenuOpen = openSubMenuItems.includes(index)

    return (
      <Box
        key={index}
        px={4}
        sx={{
          borderBottom: "1px solid #f0f0f0",
          color: "accent",
        }}
      >
        <Flex
          py={3}
          sx={{ alignItems: "center" }}
          onClick={() => toggleSubMenu(index)}
        >
          {item.title}
          <Box sx={{ transform: isSubMenuOpen ? "rotate(180deg)" : "none" }}>
            <ChevronDown size="1.5rem" />
          </Box>
        </Flex>
        <AnimateHeight duration={500} height={isSubMenuOpen ? "auto" : 0}>
          {item.subMenuItems.map((item: SubMenuItem, index) => (
            <Box key={index}>
              <Link
                sx={{
                  pl: 4,
                  py: 3,
                  display: "inline-block",
                  textDecoration: "none",
                  color: "accent",
                  "&:active": {
                    color: "primary",
                  },
                }}
                to={item.href}
                onClick={closeMenu}
              >
                {item.title}
              </Link>
            </Box>
          ))}
        </AnimateHeight>
      </Box>
    )
  }

  const classes = classNames({
    sideNavBar: true,
    sideNavBarVisible: open,
    sideNavBarAnimatable: animatable,
  })

  // if translateX does not have unit, append px to it
  const translateAmt = isNaN(translateX) ? translateX : `${translateX}px`

  const style = {
    transform: `translateX(${translateAmt})`,
  }

  return (
    <Box className={classes} onClick={closeMenu}>
      <Box
        bg="white"
        style={style}
        className="sideNavContainer"
        onClick={blockClicks}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTransitionEnd={handleTransitionEnd}
      >
        <a className="closeBtn" href="#0" onClick={closeMenu}>
          <Close size="1.8rem" color="#BE0800" />
        </a>
        <Box mt={4}>{MenuData.map(renderMenuItem)}</Box>
      </Box>
      <style jsx>{`
        .sideNavBar {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          z-index: 1;
        }
        .sideNavBar::before {
          content: "";
          display: block;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: rgba(0, 0, 0, 0.5);
          transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
        }
        .sideNavContainer {
          position: absolute;
          top: 0;
          left: 0;
          width: 80%;
          max-width: 400px;
          height: 100%;
          overflow-y: auto;
        }
        .closeBtn {
          position: absolute;
          top: 20px;
          right: 20px;
        }

        .sideNavBarVisible {
          pointer-events: auto;
          touch-action: none;
        }

        .sideNavBarVisible::before {
          opacity: 1;
        }

        .sideNavBarAnimatable .sideNavContainer {
          transition: transform 0.13s cubic-bezier(0, 0, 0.3, 1);
        }

        .sideNavBarVisible.sideNavBarAnimatable .sideNavContainer {
          transition: transform 0.33s cubic-bezier(0, 0, 0.3, 1);
        }
      `}</style>
    </Box>
  )
}

export default Drawer
