/** @jsx jsx */
import React, { useState } from "react"
import { Box, Flex, jsx } from "theme-ui"
import FacebookIcon from "../../images/facebook.svg"
import WhatsappIcon from "../../images/whatsapp.svg"
import LinkedInIcon from "../../images/linkedin.svg"
import YoutubeIcon from "../../images/youtube.svg"
import InstagramIcon from "../../images/instagram.svg"
import TelegramIcon from "../../images/telegram.svg"
import QuoraIcon from "../../images/quora.svg"

interface IconButtonProps {
  src: string
  alt: string
  isHovered: boolean
  styles?: Object
}

const IconButton = (props: IconButtonProps): JSX.Element => {
  const { src, alt, styles, isHovered } = props

  return (
    <Box
      sx={{
        width: "23px",
        mr: 2,
        transition: "all 0.3s",
        ...(!isHovered && styles),
      }}
    >
      <img sx={{ maxWidth: "100%" }} src={src} alt={alt} />
    </Box>
  )
}

const SocialIcons = (): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Flex
      mt={1}
      sx={{ alignItems: "center" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconButton
        styles={{
          transform: "translate(700%)",
          zIndex: 1,
        }}
        isHovered={isHovered}
        src={FacebookIcon}
        alt="facebook"
      />
      <IconButton
        styles={{
          transform: "translate(500%) scale(0.8)",
          opacity: 0.5,
        }}
        isHovered={isHovered}
        src={WhatsappIcon}
        alt="whatsapp"
      />
      <IconButton
        styles={{
          transform: "translate(400%) scale(0.9)",
          opacity: 0.4,
        }}
        isHovered={isHovered}
        src={LinkedInIcon}
        alt="linkedIn"
      />
      <IconButton
        styles={{
          transform: "translate(350%) scale(0.8)",
          opacity: 0.3,
        }}
        isHovered={isHovered}
        src={YoutubeIcon}
        alt="youtube"
      />
      <IconButton
        styles={{
          transform: "translate(200%) scale(0.9)",
          opacity: 0.5,
        }}
        isHovered={isHovered}
        src={InstagramIcon}
        alt="instagram"
      />
      <IconButton
        styles={{
          transform: "translate(0%) scale(0.9)",
          opacity: 0.4,
        }}
        isHovered={isHovered}
        src={TelegramIcon}
        alt="telegram"
      />
      <IconButton
        styles={{
          transform: "translate(-50%) scale(0.8)",
          opacity: 0.3,
        }}
        isHovered={isHovered}
        src={QuoraIcon}
        alt="quora"
      />
    </Flex>
  )
}

export default SocialIcons
