/** @jsx jsx */
import React, { useState } from "react"
import Logo from "../../images/logo.svg"
import { Flex, MenuButton, jsx } from "theme-ui"
import Drawer from "./Drawer"
import EnrollButton from "./EnrollButton"
import { Link } from "gatsby"

export const headerHeight = "60px"

interface MobileMenuProps {
  shrinkHeight: boolean
}

const MobileMenu = (props: MobileMenuProps) => {
  const [open, setOpen] = useState(false)
  const { shrinkHeight } = props

  const handleAnchorClick = e => {
    e.preventDefault()
    setOpen(true)
  }

  return (
    <nav
      sx={{
        ...(shrinkHeight
          ? {
              position: "fixed",
              top: "0",
              boxShadow: 1,
            }
          : {}),
        zIndex: 2,
        background: "rgba(255, 255, 255, 0.95)",
        width: "100%",
        "@media (min-width: 800px)": {
          "&": {
            display: "none",
          },
        },
      }}
    >
      <Drawer open={open} onClose={() => setOpen(false)} />
      <Flex px={2} py={3}>
        <MenuButton aria-label="Toggle Menu" onClick={handleAnchorClick} />
        <Flex px={2} sx={{ flex: 1, justifyContent: "space-between" }}>
          <Link to="/">
            <img height="35" src={Logo} alt="logo" />
          </Link>
          <EnrollButton />
        </Flex>
      </Flex>
    </nav>
  )
}

export default MobileMenu
