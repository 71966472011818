export interface SubMenuItem {
  title: string
  href: string
  partiallyActive?: boolean
}

interface MenuItemWithSubMenu {
  title: string
  hasSubMenu: true
  subMenuItems: SubMenuItem[]
  partiallyActive?: boolean
}

interface MenuItemWithoutSubMenu {
  title: string
  href: string
  hasSubMenu: false
  partiallyActive?: boolean
}

export type MenuItem = MenuItemWithSubMenu | MenuItemWithoutSubMenu

const MenuData: MenuItem[] = [
  {
    title: "About Us",
    hasSubMenu: true,
    subMenuItems: [
      {
        href: "/about",
        title: "Overview",
      },
      {
        href: "/about#vision",
        title: "Vision",
      },
      {
        href: "/about#mission",
        title: "Mission",
      },
      {
        href: "/careers",
        title: "Careers",
      },
    ],
  },
  { title: "Programmes", href: "/course", hasSubMenu: false },
  { title: "Why Sarkar IAS ACADEMY?", href: "/why", hasSubMenu: false },
  {
    title: "Study Materials",
    hasSubMenu: true,
    subMenuItems: [
      { href: "/studyMaterials/books", title: "Books by Sarkar Academy" },
      {
        href: "/studyMaterials/importantLinks",
        title: "Crucial links that you shouldn't miss include..",
      },
      {
        href: "/studyMaterials/upscReferencePrelims",
        title: "UPSC Reference - Prelims",
      },
      {
        href: "/studyMaterials/upscReferenceMains",
        title: "UPSC Reference - Mains",
      },
      { href: "/studyMaterials/currentAffairs", title: "Current Affairs" },
      { href: "/studyMaterials/tnpsc", title: "TNPSC" },
      {
        href: "/studyMaterials/prevQuestionPapers",
        title: "Previous Year Question Papers",
      },
    ],
  },
  { title: "Blog", href: "/blog", hasSubMenu: false },
]

export default MenuData
